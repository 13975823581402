<template>
  <div class="m-login-form">
    <a href="/" class="m-login-form__logo-link">
      <img src="@/assets/logo_zone.svg" width="187" height="69" class="m-login-form__logo" />
    </a>
    <p class="m-login-form__suptitle">{{ $t('welcomeportal') }}</p>
    <h2 class="m-login-form__title">{{ $t('forgotpass') }}</h2>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <input-gcr label="email" type="email" invalidText="emailrequired" v-model="email" :submitted="submitted" />
      </div>
      <div class="row">
        <div class="col">
          <a href="/" class="m-login-form__btn btn btn-outline-gray btn-sm btn-block">{{ $t('cancel') }}</a>
        </div>
        <div class="col">
          <button-gcr
            btnClass="m-login-form__btn btn-primary btn-block btn-arrow btn-sm"
            label="resetpassword"
            :loading="reseting"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import i18n from '@/i18n'
import { userService } from '@/_services'
import InputGcr from '@/components/atoms/Input'
import ButtonGcr from '@/components/atoms/Button'
import { Consts } from '@/_helpers'

require('../../utilities/button')

export default {
  components: { InputGcr, ButtonGcr },
  data() {
    return {
      email: '',
      submitted: false,
      reseting: false
    }
  },
  methods: {
    handleSubmit(evt) {
      this.submitted = true

      if (this.email) {
        this.reseting = true
        userService.forgotpassword(this.email).then(
          () => {
            this.reseting = false
            this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: i18n.t('resetPasswordEmailSent', { emailAddress: this.email }) })
            this.$router.push('/')
          },
          error => {
            this.reseting = false
            this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'unexpectedError' })
          }
        )
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
.m-login-form {
  &__forgotpass {
    font-size: 14px;
  }
  &__suptitle {
    color: gray('gray-400');
    font-weight: 500; //font-weight: 400;;
    margin: 0;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 16px;
  }
  &__text {
    font-size: 14px;
  }
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__btn {
    margin-top: 24px;
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .m-login-form__logo-link {
    display: none;
  }
}
</style>
