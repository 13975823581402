<template>
  <div class="m-login-message">
    <a href="/" class="m-login-message__logo-link">
      <img src="@/assets/logo_zone.svg" width="187" height="69" class="m-login-message__logo" />
    </a>
    <h1 class="m-login-message__title">{{ $t('loginMessTitle') }}</h1>
    <check-list :items="items" />
  </div>
</template>

<script>
import CheckList from '@/components/atoms/CheckList'

export default {
  components: { CheckList },
  data() {
    return {
      items: [
        { text: 'loginMessList1' },
        { text: 'loginMessList2' },
        { text: 'loginMessList3' },
        { text: 'loginMessList4' },
        { text: 'loginMessList5' }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';

.m-login-message {
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__title {
    margin: 18px 0;
  }
}
</style>
